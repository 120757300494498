import { Component, ReactNode } from "react";

interface Props<T> {
  children: (data: State<T> & { trigger: any }) => ReactNode;
  request: (data: T) => Promise<T>;
}

interface State<T> {
  isLoading: boolean | null;
  error: boolean | null;
  data: T | null;
}

class WithRequest<T> extends Component<Props<T>, State<T>> {
  state = {
    isLoading: null,
    error: null,
    data: null
  };

  trigger = async (d: T) => {
    try {
      this.setState({ isLoading: true });
      const data = await this.props.request(d);
      this.setState({
        data,
        isLoading: false
      });
    } catch {
      this.setState({
        isLoading: false,
        error: true
      });
    }
  };

  render() {
    if (!this.props.children) {
      return null;
    }

    return this.props.children({
      ...this.state,
      trigger: this.trigger
    });
  }
}

export default WithRequest;
