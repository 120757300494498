import React from "react";
import "./App.css";
import { AuthContextProvider } from "./context/AuthContext";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { User } from "./views/User";
import Home from "./views/Home";

export default () => (
  <AuthContextProvider>
    <BrowserRouter>
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route exact={true} path="/user" component={User} />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  </AuthContextProvider>
);
