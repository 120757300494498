import React from "react";
import { Layout } from "../../components/Layout";

const Index: React.FC = () => {
  return (
    <Layout>
      <div data-test="home-root" className="app">
        Something something..
      </div>
    </Layout>
  );
};

export default Index;
