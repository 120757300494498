import React from "react";
import { FieldProps, Field } from "formik";

import "./inputfield.css";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  name: string;
}

export const InputField = ({
  label,
  name,
  placeholder,
  type = "text",
  ...rest
}: Props) => {
  return (
    <Field
      label={label}
      placeholder={placeholder}
      name={name}
      render={({ field }: FieldProps) => {
        return (
          <div className="inputfield">
            <label htmlFor={name}>{label}</label>
            <input
              type={type}
              id={name}
              placeholder={placeholder}
              {...field}
              {...rest}
            />
          </div>
        );
      }}
    />
  );
};
