import React, { useContext } from "react";
import { pick } from "lodash";

import { ProfileForm } from "./ProfileForm";
import { Layout } from "../../components/Layout";
import { AuthContext } from "../../context/AuthContext";

import "./user.css";

const ProfileFormWrapper: React.FunctionComponent<any> = () => {
  const { user, error, isLoading } = useContext(AuthContext);

  if (isLoading) {
    return <div>Loading..</div>;
  }

  if (!user) {
    return null;
  }

  if (error) {
    return <div>Error happened..</div>;
  }

  return (
    <ProfileForm
      initialValues={pick(user, [
        "nickname",
        "given_name",
        "family_name",
        "email",
        "phone_number",
        "user_metadata"
      ])}
    />
  );
};

export const User = () => {
  return (
    <Layout>
      <div className="user">
        <div className="user-inner">
          <h1>User profile</h1>
          <ProfileFormWrapper />
        </div>
      </div>
    </Layout>
  );
};
