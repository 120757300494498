import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";

import "./header.css";

export const Header: React.FunctionComponent<{}> = props => {
  const { user, login, logout } = useContext(AuthContext);

  return (
    <header className="header">
      <div className="left">
        <Link to="home">
          <img
            alt="dolan-duck"
            className="logo"
            src="https://i.kym-cdn.com/entries/icons/original/000/003/549/Dolan.jpg"
          />
        </Link>
        <h1>Donald</h1>
        <div className="links">
          <NavLink exact to="/" activeClassName="is-active">
            Home
          </NavLink>
          {user ? (
            <NavLink exact to="/user" activeClassName="is-active">
              Profile
            </NavLink>
          ) : null}
        </div>
      </div>

      <div className="header-btns">
        {!user ? (
          <button className="header-btn" onClick={login}>
            Login
          </button>
        ) : null}
        {user ? (
          <button className="header-btn" onClick={logout}>
            Logout
          </button>
        ) : null}
      </div>
    </header>
  );
};
