import React from "react";
import { Formik, Form } from "formik";
import { pick } from "lodash";

import { User } from "../../../models/User";
import ApiRequest from "../../../components/ApiRequest";
import api from "../../../api";

import "./profileform.css";
import { InputField } from "../../../components/form/InputField";

const pickUpdatableFields = (user: Partial<User>) =>
  pick(user, ["given_name", "family_name", "user_metadata"]);

interface Props {
  initialValues: Partial<User>;
}

export const ProfileForm = ({ initialValues }: Props) => {
  return (
    <ApiRequest request={api.user.update}>
      {({ isLoading, error, data, trigger }) => {
        if (isLoading) {
          return "Loading..";
        }

        if (error) {
          return "Error happened..";
        }

        return (
          <Formik
            initialValues={data || initialValues}
            onSubmit={user => trigger(pickUpdatableFields(user))}
            render={() => (
              <Form className="profile-form" noValidate autoComplete="off">
                <InputField
                  label="Nickname"
                  placeholder="Nickname"
                  name="nickname"
                  disabled
                />
                <InputField
                  label="Email address"
                  type="text"
                  placeholder="Email address"
                  name="email"
                  disabled
                />
                <InputField
                  label="First name"
                  type="text"
                  placeholder="First name"
                  name="given_name"
                />
                <InputField
                  label="Last name"
                  type="text"
                  placeholder="Last name"
                  name="family_name"
                />
                <InputField
                  label="Phone number"
                  type="text"
                  placeholder="Phone number"
                  name="phone_number"
                  disabled
                />
                <InputField
                  label="Street"
                  type="text"
                  placeholder="Street"
                  name="user_metadata.address.street"
                />
                <InputField
                  label="City"
                  type="text"
                  placeholder="City"
                  name="user_metadata.address.city"
                />
                <InputField
                  label="State"
                  type="text"
                  placeholder="State"
                  name="user_metadata.address.state"
                />
                <InputField
                  label="Zip code"
                  type="text"
                  placeholder="Zip code"
                  name="user_metadata.address.zip"
                />
                <button type="submit">Save</button>
              </Form>
            )}
          />
        );
      }}
    </ApiRequest>
  );
};
