import React from "react";

import "./layout.css";
import { Header } from "../Header";

interface Props {}

export const Layout: React.FunctionComponent<Props> = ({ children }) => (
  <div className="layout">
    <Header />
    <div className="layout-inner">{children}</div>
  </div>
);
