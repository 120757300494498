import axios from "axios";

const createClient = () =>
  axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URI,
    withCredentials: true,
    headers: {
      "X-Requested-With": "XMLHttpRequest"
    }
  });

export const apiClient = createClient();

const refreshClient = createClient();

/**
 * Refresh the access token when an endpoint return 401 and retry the original request.
 */
apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      !originalRequest.headers["x-is-retry-request"]
    ) {
      originalRequest.headers["x-is-retry-request"] = true;

      await refreshClient.post(
        `${process.env.REACT_APP_BACKEND_URI}/auth/refresh`
      );

      return apiClient(originalRequest);
    }

    return Promise.reject(error);
  }
);
