import React from "react";

import { User } from "../models/User";
import api from "../api";
import { useAsync } from "react-async";

interface ContextProps {
  user: User;
  error: Error;
  isLoading: boolean;
  login: () => void;
  logout: () => void;
}

export const AuthContext = React.createContext<Partial<ContextProps>>({});

export const AuthContextProvider: React.FunctionComponent<{}> = ({
  children,
  ...rest
}) => {
  const { data: user, error, isLoading } = useAsync({
    promiseFn: api.user.getSelf
  });

  const login = () =>
    window.location.assign(`${process.env.REACT_APP_BACKEND_URI}/auth/login`);

  const logout = () =>
    window.location.assign(`${process.env.REACT_APP_BACKEND_URI}/auth/logout`);

  return (
    <AuthContext.Provider
      value={{ user, error, isLoading, login, logout }}
      {...rest}
    >
      {children}
    </AuthContext.Provider>
  );
};
